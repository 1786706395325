import React, { useState } from "react";
import "./Navbar.css";
import { BsBookmark, BsBookmarkPlus } from "react-icons/bs";
import { VscHistory } from "react-icons/vsc";
import HistoryTab from "../HistoryTab/HistoryTab";
import SavedTab from "../SavedTab/SavedTab";

function Navbar({ histArr, setShowDetailsDiv, savedDetails, setShowSaveBut }) {
  const [openHistoryTab, setOpenHistoryTab] = useState(false);
  const [openSavedTab, setOpenSavedTab] = useState(false);

  return (
    <div className="navbar-div">
      <p>
        Geo<span style={{ color: "#ECCF2D" }}>Risk</span>
      </p>
      <div className="nav-bar-mobile">
        <div
          onClick={() => {
            setOpenHistoryTab(!openHistoryTab);
            if (openSavedTab) {
              setOpenSavedTab(false);
            }
          }}
        >
          <VscHistory />
        </div>
        <div
          onClick={() => {
            // localStorage.setItem("savedLocations", []);
            setOpenSavedTab(!openSavedTab);
            if (openHistoryTab) {
              setOpenHistoryTab(false);
            }
          }}
        >
          <BsBookmark />
        </div>
        {openHistoryTab && (
          <HistoryTab
            histArr={histArr}
            setShowDetailsDiv={setShowDetailsDiv}
            setShowSaveBut={setShowSaveBut}
          />
        )}
        {openSavedTab && (
          <SavedTab
            savedDetails={savedDetails}
            setShowDetailsDiv={setShowDetailsDiv}
            setShowSaveBut={setShowSaveBut}
          />
        )}
      </div>
    </div>
  );
}

export default Navbar;
