import React, { useState, useEffect, useRef } from "react";
import Details from "../Details/Details";
import "./Map.css";
import { SlClose } from "react-icons/sl";
import mapboxgl from "mapbox-gl";
import mapboxglSupported from "@mapbox/mapbox-gl-supported";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import StylesControl from "@mapbox-controls/styles";
import "@mapbox-controls/styles/src/index.css";

// Replace with your Mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiYW5pbWVzaC1kZnkiLCJhIjoiY2xkaW5ucTMzMGMxNTN2cXAwMjN6OGN0dyJ9.WxWP_MYZiJU4SIAC2h7wkw";

const Map = ({
  setHistArr,
  showDetailsDiv,
  setSavedDetails,
  setShowSaveBut,
  showSaveBut,
  setShowDetailsDiv,
}) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [currentStyle, setCurrentStyle] = useState(
    "mapbox://styles/mapbox/streets-v12"
  );
  const markerRef = useRef();
  const [lng, setLng] = useState(77.58);
  const [lat, setLat] = useState(12.93);
  const [showDetails, setShowDetails] = useState(false);
  const [confirmLocationBox, setConfirmLocationBox] = useState(false);
  const [triggerClickEvent, setTriggerClickEvent] = useState(false);
  // const [history, setHistory] = useState(() => {
  //   const savedHistory = localStorage.getItem("history");
  //   return savedHistory ? JSON.parse(savedHistory) : [];
  // });
  // const [history, setHistory] = useState([]);
  const [webglSupported, setWebglSupported] = useState(true);

  const coordinatesGeocoder = (query) => {
    const matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    console.log(matches, "matches");
    if (!matches) {
      return null;
    }

    function coordinateFeature(lng, lat) {
      return {
        center: [lng, lat],
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
        place_name: "Lat: " + lat + " Lng: " + lng,
        place_type: ["coordinate"],
        properties: {},
        type: "Feature",
      };
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
      geocodes.push(coordinateFeature(coord1, coord2));
    }

    if (coord2 < -90 || coord2 > 90) {
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    if (geocodes.length === 0) {
      // geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes;
  };

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: currentStyle,
      center: [lng, lat], // Replace with your initial center coordinates
      zoom: 12,
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      localGeocoder: coordinatesGeocoder,
      zoom: 14,
      placeholder: "Enter city name",
      mapboxgl: mapboxgl,
      reverseGeocode: true,
    });

    // Add navigation controls (e.g., zoom controls)
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    // Add Geocoder controls
    mapRef.current.addControl(geocoder, "top-left");
    // Add geolocate control to the map.
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      })
    );
    // with custom styles:
    mapRef.current.addControl(
      new StylesControl({
        compact: true,
        styles: [
          {
            label: "Streets",
            styleName: "Mapbox Streets",
            styleUrl: "mapbox://styles/mapbox/streets-v9",
          },
          {
            label: "Satellite",
            styleName: "Satellite",
            styleUrl: "mapbox://styles/mapbox/satellite-streets-v12",
          },
          {
            label: "Light",
            styleName: "Light",
            styleUrl: "mapbox://styles/mapbox/light-v11",
          },
          {
            label: "Dark",
            styleName: "Dark",
            styleUrl: "mapbox://styles/mapbox/dark-v11",
          },
        ],
      }),
      "top-right"
    );

    geocoder.on("result", () => {
      if (markerRef.current) {
        markerRef.current.remove();
      }
    });

    mapRef.current.on("click", async (e) => {
      const clickedLng = e.lngLat.lng.toFixed(4);
      const clickedLat = e.lngLat.lat.toFixed(4);
      setLng(clickedLng);
      setLat(clickedLat);
      setConfirmLocationBox(true);
      setTriggerClickEvent(false);
      setShowDetails(false);
      setShowSaveBut(false);
    });
  }, []);

  useEffect(() => {
    if (triggerClickEvent) {
      setShowDetails(true);
      setShowSaveBut(true);
      // const currentTime = new Date();
      // const formattedTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`; // Step 1: Format the timestamp

      // const clickDetails = {
      //   lat: lat,
      //   lng: lng,
      //   time: formattedTime,
      // };

      // setHistory((prevHistory) => {
      //   const savedHistory = localStorage.getItem("history");
      //   if (savedHistory) prevHistory = JSON.parse(savedHistory);
      //   else prevHistory = [];
      //   return [...prevHistory, clickDetails];
      // });

      // setHistory((prevHistory) => {
      //   return [...prevHistory, clickDetails];
      // });

      if (markerRef.current) {
        markerRef.current.remove();
      }

      markerRef.current = new mapboxgl.Marker({
        color: "#000 !important",
      })
        .setLngLat([lng, lat])
        .addTo(mapRef.current);

      mapRef.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: 14,
      });
    }
  }, [triggerClickEvent]);

  useEffect(() => {
    if (showDetailsDiv.flag) {
      setLat(showDetailsDiv.lat);
      setLng(showDetailsDiv.lng);
    }
  }, [showDetailsDiv]);

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.remove();
    }
    if (lat != 12.93 && lng != 77.58) {
      markerRef.current = new mapboxgl.Marker({
        color: "#000 !important",
      })
        .setLngLat([lng, lat])
        .addTo(mapRef.current);

      mapRef.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: 14,
      });
    }
  }, [lat, lng]);

  // useEffect(() => {
  //   localStorage.setItem("history", JSON.stringify(history));
  //   setHistArr(history);
  // }, [history]);

  // if (!webglSupported) {
  //   return (
  //     <div className="map-error">Your browser does not support WebGL.</div>
  //   );
  // }

  return (
    <div className="map-div">
      <div ref={mapContainerRef} className="map-container"></div>
      {confirmLocationBox && (
        <div className="confirm-location-div">
          <div className="save-box-nav">
            <p>Confirm Location</p>
            <SlClose
              onClick={() => {
                setConfirmLocationBox(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ color: "white" }}>
            <p>Lat: {lat}</p>
            <p>Lng: {lng}</p>
          </div>
          <div className="confirm-buttons">
            <div
              onClick={() => {
                setConfirmLocationBox(false);
                setTriggerClickEvent(true);
              }}
              className="save-button"
            >
              Confirm
            </div>
            <div
              onClick={() => {
                setConfirmLocationBox(false);
              }}
              className="change-button"
            >
              Change Location
            </div>
          </div>
        </div>
      )}
      {(showDetails || showDetailsDiv.flag) && (
        <Details
          lat={lat}
          lng={lng}
          setShowDetails={setShowDetails}
          setSavedDetails={setSavedDetails}
          showDetailsDiv={showDetailsDiv}
          showSaveBut={showSaveBut}
          setShowSaveBut={setShowSaveBut}
          showDetails={showDetails}
          setShowDetailsDiv={setShowDetailsDiv}
        />
      )}
    </div>
  );
};

export default Map;
