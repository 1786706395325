import React, { useState } from "react";
import Navbar from "./Components/Navbar/Navbar";
import Sidebar from "./Components/Sidebar/Sidebar";
import Map from "./Components/Map//Map";
import "./App.css";

function App() {
  const [histArr, setHistArr] = useState([]);
  const [showDetailsDiv, setShowDetailsDiv] = useState({
    flag: false,
    lat: "",
    lng: "",
  });
  const [savedDetails, setSavedDetails] = useState([]);
  const [showSaveBut, setShowSaveBut] = useState(false);
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Navbar
        histArr={histArr}
        setShowDetailsDiv={setShowDetailsDiv}
        savedDetails={savedDetails}
        setShowSaveBut={setShowSaveBut}
      />
      <div className="sidebar-map-div">
        <Sidebar
          histArr={histArr}
          setShowDetailsDiv={setShowDetailsDiv}
          savedDetails={savedDetails}
          setShowSaveBut={setShowSaveBut}
        />
        <Map
          setHistArr={setHistArr}
          showDetailsDiv={showDetailsDiv}
          setSavedDetails={setSavedDetails}
          setShowSaveBut={setShowSaveBut}
          showSaveBut={showSaveBut}
          setShowDetailsDiv={setShowDetailsDiv}
        />
      </div>
    </div>
  );
}

export default App;
