import React, { useEffect, useState } from "react";
import "./HistoryTab.css";
import DeleteIcon from "@mui/icons-material/Delete";

function HistoryTab({ histArr, setShowDetailsDiv, setShowSaveBut }) {
  const [historyArr, setHistoryArr] = useState(() => {
    const savedHistory = localStorage.getItem("history");
    return savedHistory ? JSON.parse(savedHistory) : [];
  });

  const removeClickDetails = (index) => {
    setHistoryArr((prevHistory) => {
      const updatedHistory = prevHistory.filter((_, i) => i !== index);
      localStorage.setItem("history", JSON.stringify(updatedHistory));
      return updatedHistory;
    });
  };

  const removeAll = () => {
    setHistoryArr(() => {
      localStorage.setItem("history", []);
      return [];
    });
  };

  // useEffect(() => {
  // }, [historyArr]);

  return (
    <div className="history-div">
      <p className="hist-tab-heading">History Location</p>
      {historyArr.length > 0 ? (
        <div className="hist-tab-div">
          {historyArr.map((e, index) => (
            <div
              key={index}
              className="single-hist-detail-div"
              onClick={() => {
                setShowDetailsDiv({
                  flag: true,
                  lat: e.lat,
                  lng: e.lng,
                  data: e.data,
                });
                setShowSaveBut(true);
              }}
            >
              <div>
                <p className="timestamp">{e.time}</p>
                <DeleteIcon
                  onClick={() => removeClickDetails(index)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <p className="latlng-hist">
                lat: {e.lat}, lng: {e.lng}
              </p>
              <br />
            </div>
          ))}
          <div
            style={{ textAlign: "center" }}
            onClick={() => {
              removeAll();
            }}
          >
            Clear all
          </div>
        </div>
      ) : (
        <p>History not available</p>
      )}
    </div>
  );
}

export default HistoryTab;
