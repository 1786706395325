import React, { useEffect, useState } from "react";
import "./SavedTab.css";
import DeleteIcon from "@mui/icons-material/Delete";

function SavedTab({ savedDetails, setShowDetailsDiv, setShowSaveBut }) {
  const [savedArr, setSavedArr] = useState(() => {
    const savedLatlng = localStorage.getItem("savedLocations");
    return savedLatlng ? JSON.parse(savedLatlng) : [];
  });

  // console.log(savedArr, "saved");

  const removeClickDetails = (index) => {
    setSavedArr((prevSaved) => {
      const updatedSave = prevSaved.filter((_, i) => i !== index);
      localStorage.setItem("savedLocations", JSON.stringify(updatedSave));
      return updatedSave;
    });
  };

  const removeAll = () => {
    setSavedArr(() => {
      localStorage.setItem("savedLocations", []);
      return [];
    });
  };

  // useEffect(() => {
  //   setSavedArr(savedDetails);
  //   console.log(savedDetails, "checking loacals");
  // }, [savedDetails]);

  // console.log(savedArr, "checking ls");

  return (
    <div className="saved-div">
      <p className="hist-tab-heading">Saved Location</p>
      {savedArr.length > 0 ? (
        <div className="hist-tab-div">
          {savedArr.map((e, index) => (
            <div
              key={index}
              className="single-hist-detail-div"
              onClick={() => {
                setShowDetailsDiv({
                  flag: true,
                  lat: e.lat,
                  lng: e.lng,
                  data: e.data,
                });
                setShowSaveBut(false);
              }}
            >
              <div>
                <p>{e.name}</p>

                <DeleteIcon
                  onClick={() => removeClickDetails(index)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div>
                <p className="latlng-hist">
                  lat: {e.lat}, lng: {e.lng}
                </p>
                <p className="latlng-hist">{e.time}</p>
              </div>
              <br />
            </div>
          ))}
          <div
            style={{ textAlign: "center" }}
            onClick={() => {
              removeAll();
            }}
          >
            Clear all
          </div>
        </div>
      ) : (
        <p>No saved locations available</p>
      )}
    </div>
  );
}

export default SavedTab;
