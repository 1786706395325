import React, { useState, useEffect } from "react";
import { SlClose } from "react-icons/sl";
import { BsBookmark, BsBookmarkPlus } from "react-icons/bs";
import "./Details.css";

function Details({
  lat,
  lng,
  setShowDetails,
  setSavedDetails,
  showDetailsDiv,
  showSaveBut,
  setShowSaveBut,
  showDetails,
  setShowDetailsDiv,
}) {
  const [data, setData] = useState();

  const [savedLocations, setSavedLocations] = useState(() => {
    const savedLatlng = localStorage.getItem("savedLocations");
    return savedLatlng ? JSON.parse(savedLatlng) : [];
  });
  const [openSaveBox, setOpenSaveBox] = useState(false);
  const [savedInput, setSavedInput] = useState("");
  const [savedName, setSavedName] = useState("");
  const [history, setHistory] = useState(() => {
    const savedHistory = localStorage.getItem("history");
    return savedHistory ? JSON.parse(savedHistory) : [];
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isApiCallComplete, setIsApiCallComplete] = useState(false);

  useEffect(() => {
    localStorage.setItem("savedLocations", JSON.stringify(savedLocations));
    setSavedDetails(savedLocations);
  }, [savedLocations]);

  useEffect(() => {
    if (savedName != "") {
      const currentTime = new Date();
      const formattedTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`;
      let clickDetails = {
        lat: lat,
        lng: lng,
        time: formattedTime,
        name: savedName,
        data: data,
      };
      // console.log(clickDetails);
      setSavedLocations((prevDetails) => {
        return [...prevDetails, clickDetails];
      });
      setShowSaveBut(false);
    }
  }, [savedName]);

  useEffect(() => {
    if (showDetails) {
      const fetchDetails = async () => {
        setIsLoading(true);
        try {
          const formData = new FormData();
          formData.append(
            "latlng",
            JSON.stringify({ lat: parseFloat(lat), lng: parseFloat(lng) })
          );

          const response = await fetch(
            `https://georiskportal.skyserve.ai/api/fetch.php`,
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          setData(result);
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
        } finally {
          setIsLoading(false);
          setIsApiCallComplete(true);
        }
      };
      fetchDetails();
      // console.log(lat, lng, "fetch-details called");
    }
  }, [lat, lng, showDetails]);

  useEffect(() => {
    if (!isLoading && isApiCallComplete) {
      const currentTime = new Date();
      const formattedTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`; // Step 1: Format the timestamp

      const clickDetails = {
        lat: lat,
        lng: lng,
        time: formattedTime,
        data: data,
      };
      setHistory((prevHistory) => {
        const savedHistory = localStorage.getItem("history");
        if (savedHistory) prevHistory = JSON.parse(savedHistory);
        else prevHistory = [];
        return [...prevHistory, clickDetails];
      });
    }
  }, [isLoading]);

  useEffect(() => {
    localStorage.setItem("history", JSON.stringify(history));
    // setHistArr(history);
  }, [history]);

  useEffect(() => {
    setData(showDetailsDiv.data);
  }, [showDetailsDiv.lat, showDetailsDiv.lng]);

  // if (!showDetailsDiv && !isLoading) {
  //   return null;
  // }

  return (
    <div className="details-div">
      <div className="cross-button">
        {showSaveBut && data && (
          <div
            onClick={() => {
              setOpenSaveBox(true);
            }}
          >
            <BsBookmarkPlus />
          </div>
        )}
        <div
          onClick={() => {
            setShowDetails(false);
            setShowDetailsDiv(false);
          }}
        >
          <SlClose />
        </div>
      </div>
      {openSaveBox && (
        <div className="save-box-div">
          <div className="save-box-nav">
            <p>Saved Location</p>
            <SlClose
              onClick={() => {
                setOpenSaveBox(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            type="text"
            onChange={(e) => {
              const name = e.target.value;
              setSavedInput(name);
            }}
            value={savedInput}
            placeholder="Name the location"
            className="save-box-input"
          ></input>
          <div
            onClick={() => {
              setSavedName(savedInput);
              setSavedInput("");
              setOpenSaveBox(false);
            }}
            className="save-button"
          >
            Save
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="loader">Loading...</div>
      ) : data ? (
        <>
          <div className="details-div-section">
            <h4 className="section-heading">Administrative Details</h4>
            <p>
              Lat: {lat}, Lng : {lng}
            </p>
            <p>
              <span>Distance From City center: </span>
              {Math.round(parseFloat(data.DistanceFromCityCenter) * 100) / 100}m
            </p>
            <br />
            <p>
              <span>City: </span>
              {data.City === null ? "-" : data.City}
            </p>
            <p>
              <span>District: </span>
              {data.District === null ? "-" : data.District}
            </p>
            <p>
              <span>Taluk: </span>
              {data.Taluk === null ? "-" : data.Taluk}
            </p>
            <p>
              <span>Town: </span>
              {data.District === null ? "-" : data.District}
            </p>
            <p>
              <span>Village: </span>
              {data.Town === null ? "-" : data.Town}
            </p>
            <p>
              <span>Mandal: </span>
              {data.Mandal === null ? "-" : data.Mandal}
            </p>
            <p>
              <span>Hobli: </span>
              {data.Hobli === null ? "-" : data.Hobli}
            </p>
          </div>
          <div className="vl"></div>
          <div className="details-div-section">
            <div>
              <h4 className="section-heading">LULC (Land Use Land Cover)</h4>
              <p>
                <span>Water Body: </span>
                {data.WaterBody === null ? "-" : data.WaterBody}
              </p>
              <p>
                <span>Vacant: </span>
                {data.Vacant === null ? "-" : data.Vacant}
              </p>
              <p>
                <span>Within Green Belt: </span>
                {data.WithinGreenBelt === false
                  ? "False"
                  : data.WithinGreenBelt}
              </p>
            </div>
            <div>
              <h4 className="section-heading">Hazard Risk</h4>
              <p>
                <span>Flood Prone: </span>
                {data.FloodProne === null ? "-" : data.FloodProne}
              </p>
              <p>
                <span>Seismic Zone: </span>
                {data.SeismicZone === null ? "-" : data.SeismicZone}
              </p>
              <p>
                <span>Wind Hazard Zone: </span>
                {data.WindHazardZone === null ? "-" : data.WindHazardZone}
              </p>
            </div>
          </div>
          <div className="vl"></div>
          <div className="details-div-section">
            <div>
              <h4 className="section-heading">Infrastructure</h4>
              <p>
                <span>Property Accessibility: </span>
                {data.PropertyAccessibility === null
                  ? "-"
                  : data.PropertyAccessibility}
              </p>
              <p>
                <span>Congested Lane: </span>
                {data.CongestedLane === false ? "False" : data.CongestedLane}
              </p>
              <p>
                <span>Within ASI Monument: </span>
                {data.WithinASIMonument === false
                  ? "False"
                  : data.WithinASIMonument}
              </p>
              <p>
                <span>Storm Water Drainage: </span>
                {data.StormWaterDrainage === null
                  ? "-"
                  : data.StormWaterDrainage}
              </p>
              <p>
                <span>Ground Water Availability: </span>
                {data.GroundWaterAvailability === null
                  ? "-"
                  : data.GroundWaterAvailability}
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="details-error">No data available</div>
      )}
    </div>
  );
}

export default Details;
