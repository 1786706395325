import React, { useState } from "react";
import "./Sidebar.css";
import { BsBookmark, BsBookmarkPlus } from "react-icons/bs";
import { VscHistory } from "react-icons/vsc";
import HistoryTab from "../HistoryTab/HistoryTab";
import SavedTab from "../SavedTab/SavedTab";

function Sidebar({ histArr, setShowDetailsDiv, savedDetails, setShowSaveBut }) {
  const [openHistoryTab, setOpenHistoryTab] = useState(false);
  const [openSavedTab, setOpenSavedTab] = useState(false);
  return (
    <div className="sidebar-div">
      <div
        onClick={() => {
          // localStorage.setItem("savedLocations", []);
          setOpenSavedTab(!openSavedTab);
          if (openHistoryTab) {
            setOpenHistoryTab(false);
          }
        }}
      >
        <BsBookmark />
        <p className="logo-name">Saved</p>
      </div>
      <div
        onClick={() => {
          setOpenHistoryTab(!openHistoryTab);
          if (openSavedTab) {
            setOpenSavedTab(false);
          }
        }}
      >
        <VscHistory />
        <p className="logo-name">History</p>
      </div>

      {openHistoryTab && (
        <HistoryTab
          histArr={histArr}
          setShowDetailsDiv={setShowDetailsDiv}
          setShowSaveBut={setShowSaveBut}
        />
      )}
      {openSavedTab && (
        <SavedTab
          savedDetails={savedDetails}
          setShowDetailsDiv={setShowDetailsDiv}
          setShowSaveBut={setShowSaveBut}
        />
      )}
    </div>
  );
}

export default Sidebar;
